import {
  Badge,
  Button,
  ButtonProps,
  Flex,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuListProps,
  MenuOptionGroup,
  Spacer,
  Text,
} from '@chakra-ui/react'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'

import { useFeatureFlag } from 'modules/featureFlags'

import {
  getSupportedLocaleOptionsSorted,
  LOCALE_ROLLOUT_MAP,
  SupportedLocaleKey,
} from '../constants'

export type LocaleOption = {
  key: SupportedLocaleKey
  displayName: string
}

type LocalePickerProps = {
  selectedLocale: LocaleOption
  onLocaleSelect: (key: SupportedLocaleKey) => void
  buttonProps?: ButtonProps
  menuListProps?: MenuListProps
  matchWidth?: boolean
}

export const LocalePicker = ({
  selectedLocale,
  onLocaleSelect,
  buttonProps,
  menuListProps,
  matchWidth = true,
}: LocalePickerProps) => {
  const canUsePrivateBetaLocales = useFeatureFlag('i18nEnabled')
  useLingui() // Force re-render whenever the lingui locale changes, which happens async upon selecting a language

  return (
    <Flex direction="column">
      <Menu matchWidth={matchWidth}>
        <MenuButton
          as={Button}
          rightIcon={<FontAwesomeIcon size="xs" icon={faChevronDown} />}
          minW="150px"
          textAlign="left"
          w="100%"
          {...buttonProps}
        >
          {selectedLocale.displayName}
        </MenuButton>
        <MenuList
          minWidth="240px"
          maxH="290px"
          overflowY="auto"
          overflowX="hidden"
          {...menuListProps}
        >
          <MenuOptionGroup
            value={selectedLocale.key}
            type="radio"
            onChange={(value: SupportedLocaleKey) => {
              onLocaleSelect(value)
            }}
          >
            {getSupportedLocaleOptionsSorted(canUsePrivateBetaLocales).map(
              (locale) => {
                const showComingSoonTag =
                  !canUsePrivateBetaLocales &&
                  LOCALE_ROLLOUT_MAP[locale.key] === 'private_beta'

                const showBetaTag =
                  !showComingSoonTag &&
                  LOCALE_ROLLOUT_MAP[locale.key] === 'public_beta'

                return (
                  <MenuItemOption
                    key={locale.key}
                    value={locale.key}
                    isDisabled={showComingSoonTag}
                  >
                    <Flex flex={1} alignItems="center">
                      <Flex direction="column">
                        <Text>{locale.displayName}</Text>
                        <Text fontSize="12px" color="gray.500">
                          {locale.displayNameLocalized()}
                        </Text>
                      </Flex>{' '}
                      <Spacer />
                      {showComingSoonTag ? (
                        <Badge ml={2} fontSize="0.75em" colorScheme="blue">
                          <Trans>Coming Soon</Trans>
                        </Badge>
                      ) : showBetaTag ? (
                        <Badge ml={2} fontSize="0.75em" colorScheme="green">
                          <Trans>Beta</Trans>
                        </Badge>
                      ) : null}
                    </Flex>
                  </MenuItemOption>
                )
              }
            )}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Flex>
  )
}
